import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { ApiServiceService } from './api-service.service';

@Injectable({
  providedIn: 'root'
})
export class ManagementGuardGuard implements CanActivate {

  constructor(
    private apiService: ApiServiceService,
    private router: Router) { }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      let isAuthenticated = this.apiService.isAuthenticated;
      console.log("MANAGEMENT AUTH GUARD")
      if (!isAuthenticated) {
          this.router.navigate(['/dash']);
      }else{
        console.log("MANAGEMENT AUTH GUARD")
        let roles =JSON.parse(localStorage.getItem("user")).roles;
        let  user_role:[]= roles.filter(item => item == "OPERATEUR" );
        if(user_role.length >0){
          this.router.navigate(['/caisse-operateur']);
        }
      }
      return isAuthenticated;
    return true;
  }

}
