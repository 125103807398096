import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PrivillegeServiceService {
  have_to_create_transfert:boolean = false;
  have_to_update_transfert:boolean = false;
  have_to_delete_transfert:boolean = false;
  have_to_pick_transfert:boolean = false;
  have_to_reverse_pick_transfert:boolean = false;
  constructor() { }

  repartitionPrivilleges(privilleges){
    privilleges.forEach(element => {
        console.log("this the Search Privillges")
        if(element == "add_transfert" || element == "creation_transfert_sortant"){
          console.log("WE HAVE TO CREATE  TRANSFERT")
          this.have_to_create_transfert = true;
        }
        if(element == "update_transfert" ){
          this.have_to_update_transfert = true;
        }
        if(element == "delete_transfert"){
          this.have_to_delete_transfert = true
        }
        if(element == "pick_transfert"){
          this.have_to_pick_transfert= true
        }

        if(element == "reverse_pick_transfer"){
          this.have_to_reverse_pick_transfert= true
        }
    });
  }
}
