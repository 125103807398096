import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ApiServiceService } from 'app/Api/api-service.service';


@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.scss']
})
export class AdminLayoutComponent implements OnInit {
  lang;
  user:any=[];
  roles:any=[];
  agence:any={}
  isoperateur:boolean = false;
  issupervisor:boolean = false;
  isadmin:boolean = false;
  is_sous_admin:boolean = false;
  ismanager:boolean = false
  manager_info:any;

issupermanager: boolean = false;
manager_number_agencies
  constructor(
    public translate : TranslateService, private apiService:ApiServiceService
  ){
    //this.apiService.getExpirationSession("TTL");
    this.userLoged()
     translate.addLangs(['fr','ar']);
     if(localStorage.getItem('lng')){
       this.translate.use(localStorage.getItem('lng'));
       localStorage.getItem('lng')=='fr'?this.lang='fr':this.lang='ar';

     }else{
       this.translate.use('fr'), localStorage.setItem('lng','fr');
       this.lang='fr'
     }
  }
  ngOnInit() { }

  userLoged(){
    this.manager_number_agencies = localStorage.getItem("manager_number_agencies")
    if(this.manager_number_agencies){this.manager_number_agencies = parseInt(this.manager_number_agencies)
    }else{
      this.manager_number_agencies = 0
    }
  }
}

