import { Component, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ApiServiceService } from 'app/Api/api-service.service';
import { NotifyService } from './Api/notify.service';

const MINUTEUR:number=600000
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit{
  counter:number=0;
  data;

  GLOBAL_ACTIVE = new Date().getTime() + MINUTEUR;
  deconnected=1;
  constructor(
    public translate : TranslateService,public apiService:ApiServiceService,private ngZone:NgZone
    ,private notifyService: NotifyService,private route:Router,private modal: NgbModal
  ){

     translate.addLangs(['fr','ar']);
     if(localStorage.getItem('lng')){
       this.translate.use(localStorage.getItem('lng'));
     }else{
       this.translate.use('fr'), localStorage.setItem('lng','fr');
     }
     this.GLOBAL_ACTIVE = new Date().getTime() + MINUTEUR;
     this.counter=0
  }

  setActive(ttl){

    if(this.GLOBAL_ACTIVE > ttl){
      this.data = {
        'active':true,
        'last_time':ttl,
        'iteration':this.counter++
      };
        this.GLOBAL_ACTIVE = new Date().getTime() + MINUTEUR;
        this.TriggerActivity();
    }else{
      if(localStorage.getItem("token") == null){
        this.deconnected=0;
      }else{
        console.log("disconnected without the trigger")
        this.deconnected=0;
        this.apiService.doLogout();
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        localStorage.removeItem('privilleges');
        this.GLOBAL_ACTIVE = new Date().getTime() + MINUTEUR
        this.modal.dismissAll("Close All")
        //this.notifyService.successNotify('top','center',"Votre session est expire veuillez reconnecter");
        this.route.navigate(['/dash']);

      }

    }
  }

  run() {
    var date = new Date();
    var now = date.getTime();
    window.onload = () => { this.setActive(now); };
    window.onmousemove = () => { this.setActive(now); };
    window.onmousedown = () => { this.setActive(now); };
    window.onclick = () => { this.setActive(now); };
    window.onscroll = () => { this.setActive(now); };
    window.onkeypress = () => { this.setActive(now); };
  }
  ngOnInit() {
    this.GLOBAL_ACTIVE = new Date().getTime() + MINUTEUR
    this.counter=0
    if(this.deconnected==1){
      this.run();
    }else{
      this.TriggerActivity();
    }

  }

  TriggerActivity(){
    this.ngZone.runOutsideAngular(()=>{
      setTimeout(()=>{
        this.ngZone.run(()=>{
           if(this.GLOBAL_ACTIVE <= this.data.last_time){
             this.deconnected=0;
             this.apiService.doLogout();
             console.log("disconnected using the trigger")
             localStorage.removeItem('token');
             localStorage.removeItem('refreshToken');
             localStorage.removeItem('user');
             localStorage.removeItem('agence');
             localStorage.removeItem('privilleges');
             //this.GLOBAL_ACTIVE = new Date().getTime() + 60000
             //this.notifyService.successNotify('top','center',"Votre session est expire veuillez reconnecter");
             this.route.navigate(['/dash']);
           }else{
            this.run();
           }
        })
      },MINUTEUR);
    })
  }
}
