import { Routes } from '@angular/router';
import { AuthGuard } from './Api/auth.guard';

import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { LoginComponent } from './login/login.component';
import { NotFoundComponent } from "./not-found/not-found.component";


export const AppRoutes: Routes = [
  {
    path: '',
    redirectTo: 'dash',
    pathMatch: 'full',
  },
  /*{
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },*/ {
    path : 'dash',
    component : LoginComponent
  },
  {
    path : 'notfound',
    component : NotFoundComponent
  },


  {
    path: '',
    component: AdminLayoutComponent,
    children: [
        {
      path: '',
      loadChildren: './layouts/admin-layout/admin-layout.module#AdminLayoutModule',
      canActivate: [AuthGuard]

  }]},
  {
    path: '**',

    redirectTo: 'notfound'
  },
  //{
  //  path: 'user',
   // component: UserComponent,
 // }
]
