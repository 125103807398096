import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NavbarComponent } from './navbar.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import{TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgxPaginationModule } from 'ngx-pagination';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { OtherBankComponent } from './other-bank/other-bank.component';

export function createTranslateLoader(http: HttpClient){
    return new TranslateHttpLoader(http  );
  }

@NgModule({
    imports: [ RouterModule, CommonModule, NgbModule ,
        FormsModule,
    ReactiveFormsModule,
    NgMultiSelectDropDownModule.forRoot(),
        TranslateModule.forRoot({
            loader:{
              provide: TranslateLoader,
              useFactory: (createTranslateLoader),
              deps:[HttpClient]
            }
          }),
          HttpClientModule,
          NgxPaginationModule,],
    declarations: [ NavbarComponent, OtherBankComponent ],
    exports: [ NavbarComponent ]
})

export class NavbarModule {}
