import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ToastrModule } from "ngx-toastr";
import{HttpClientModule, HttpClient, HttpHeaders, HTTP_INTERCEPTORS} from '@angular/common/http';
import { SidebarModule } from './sidebar/sidebar.module';
import { FooterModule } from './shared/footer/footer.module';
import { NavbarModule} from './shared/navbar/navbar.module';
import { FixedPluginModule} from './shared/fixedplugin/fixedplugin.module';
import { NotFoundComponent } from "./not-found/not-found.component";
import { AppComponent } from './app.component';
import { AppRoutes } from './app.routing';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { LoginComponent } from './login/login.component';
import { ReactiveFormsModule,FormsModule } from "@angular/forms";
import { ApiServiceService } from "./Api/api-service.service";
import { PrivillegeServiceService } from "./Api/privillege-service.service";
import{TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { AuthGuard } from "./Api/auth.guard";
import { ManagementGuardGuard } from "./Api/management-guard.guard";
import { NotifyService } from "./Api/notify.service";

import { NgbNavModule } from "@ng-bootstrap/ng-bootstrap";
//import { SessionTTLInterceptor } from "./Api/session-ttl.interceptor";
import { DistributionsFeesService } from "./Api/distributions-fees.service";
import { TransfertService } from "./Api/transfert.service";



export function createTranslateLoader(http: HttpClient){
  return new TranslateHttpLoader(http  );
}

@NgModule({
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    LoginComponent,
    NotFoundComponent,


  ],
  imports: [
    BrowserAnimationsModule,
    NgbNavModule,
    RouterModule.forRoot(AppRoutes,{
      useHash: true
    }),
    TranslateModule.forRoot({
      loader:{
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps:[HttpClient]
      }
    }),
    HttpClientModule,
    SidebarModule,
    ReactiveFormsModule,
    FormsModule,
    NavbarModule,
    ToastrModule.forRoot(),
    FooterModule,
    FixedPluginModule,
    NgMultiSelectDropDownModule.forRoot()
  ],
  schemas:[NO_ERRORS_SCHEMA],
  providers: [ApiServiceService,AuthGuard,ManagementGuardGuard,NotifyService,PrivillegeServiceService,DistributionsFeesService,TransfertService,],
  bootstrap: [AppComponent]
})
export class AppModule { }
