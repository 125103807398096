import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ApiServiceService } from 'app/Api/api-service.service';
import { NotifyService } from 'app/Api/notify.service';

//
export interface Manager {
    path: string;
    title: string;
    icon: string;
    code: string
    class: string;
}
export interface Admin {
    path: string;
    title: string;
    icon: string;
    class: string;
    code:string
}
export interface Operateur {
    path: string;
    title: string;
    icon: string;
    code:string;
    class: string;
}
export interface Super {
    path: string;
    title: string;
    code:string
    icon: string;
    class: string;
}

@Component({
    moduleId: module.id,
    selector: 'sidebar-cmp',
    templateUrl: 'sidebar.component.html',
})

export class SidebarComponent implements OnInit {

   MANAGER: Manager[] = [
    { path: '/account-details', code:"caisse",     title:'Acceuil',         icon:'nc-bank',       class: '' },
    { path: '/user', code:"user",   title: 'Utilisateurs',        icon:'nc-single-02', class: '' },
    { path: '/agence',  code:"agence",  title: 'Agences',        icon:'nc-bank', class: '' },
   // { path: '/associes',   code:"associes",      title: this.translate.instant('Sidebar.associes'),       icon:'nc-circle-10',    class: '' },
    { path: '/account', code:"account",   title:'Comptes',        icon:'nc-money-coins', class: '' },
    { path: '/depot',   code:"depot",      title:'Depots',             icon:'nc-chart-bar-32',    class: '' },
    { path: '/charge',   code:"charge",      title: 'Charges',      icon:'nc-chart-bar-32',    class: '' },
    { path: '/capital',   code:"capital",      title: 'Capital',             icon:'nc-money-coins',    class: '' },
    { path: '/journal',   code:"journal",      title:'Journal',             icon:'nc-circle-10',    class: '' },
    { path: '/profil',   code:"profil",      title: 'Profil',             icon:'nc-circle-10',    class: '' },
    { path: '',   code:"deconnect",      title: 'Déconnexion',             icon:'nc-icon nc-lock-circle-open',    class: '' },

    //{ path: '',   code:"lng",      title:this.translate.instant('Sidebar.lang'),             icon:'',    class: '' },

  ];
   SUPER: Super[] = [
    { path: '/account-details', code:"caisse",     title:'Acceuil',         icon:'nc-bank',       class: '' },
    { path: '/user', code:"user",   title: 'Utilisateurs',        icon:'nc-single-02', class: '' },
    { path: '/agence',  code:"agence",  title: 'Agences',        icon:'nc-bank', class: '' },
   // { path: '/associes',   code:"associes",      title: this.translate.instant('Sidebar.associes'),       icon:'nc-circle-10',    class: '' },
    { path: '/account', code:"account",   title:'Comptes',        icon:'nc-money-coins', class: '' },
    { path: '/depot',   code:"depot",      title:'Depots',             icon:'nc-chart-bar-32',    class: '' },
    { path: '/charge',   code:"charge",      title: 'Charges',      icon:'nc-chart-bar-32',    class: '' },
    { path: '/capital',   code:"capital",      title: 'Capital',             icon:'nc-money-coins',    class: '' },
    { path: '/journal',   code:"journal",      title:'Journal',             icon:'nc-circle-10',    class: '' },
    { path: '/profil',   code:"profil",      title: 'Profil',             icon:'nc-circle-10',    class: '' },
    { path: '',   code:"deconnect",      title: 'Déconnexion',             icon:'nc-icon nc-lock-circle-open',    class: '' },

    ];
    ADMIN: Admin[] = [

        { path: '/caisse-admin',  code:"caisse_admin",   title:'Acceuil',         icon:'nc-bank',       class: '' },
        { path: '/journal',   code:"journal",      title: 'Journal',             icon:'nc-circle-10',    class: '' },
        { path: '/associes',   code:"associes",      title:'Géstionnaires',       icon:'nc-circle-10',    class: '' },
        { path: '/user', code:"user",   title: 'Utilisateurs',        icon:'nc-single-02', class: '' },
        { path: '/agence',  code:"agence",  title: 'Agences',        icon:'nc-bank', class: '' },
        { path: '/account', code:"account",   title: 'Comptes',        icon:'nc-money-coins', class: '' },
       // { path: '/caisse-admin',   code:"caisse_admin",      title: this.translate.instant('Sidebar.caisse'),       icon:'nc-pin-3',    class: '' },
        { path: '/journal-transactions',   code:"journal_transcations",      title: 'Transactions',       icon:'nc-chart-bar-32',    class: '' },

        { path: '/profil',   code:"profil",      title:'Profil',             icon:'nc-circle-10',    class: '' },
        { path: '/role',     code:"role",     title: 'Roles',             icon:'nc-tag-content',      class: '' },

      //  { path: '/accord',      title: this.translate.instant('Sidebar.accord'),            icon:'nc-briefcase-24',    class: '' },
      //  { path: '/type-transfert',      title: this.translate.instant('Transfert.type'),              icon:'nc-send',  class: '' },
        { path: '/distributions',  code:"distributions",   title: 'Distributions',         icon:'nc-briefcase-24', class: '' },
     //   { path: '/rapport',         title: this.translate.instant('Dashboard.rapport'),       icon:'nc-pin-3',    class: '' },

        { path: '/ville',   code:"ville",      title: 'Commune',       icon:'nc-pin-3',    class: '' },
        { path: '',   code:"deconnect",      title: 'Déconnexion',             icon:'nc-icon nc-lock-circle-open',    class: '' },
        //{ path: '',   code:"lng",      title:this.translate.instant('Sidebar.lang'),             icon:'',    class: '' },



    ];
    SOUSADMIN: Admin[] = [

      { path: '/profil',   code:"profil",   title:  'Profil',         icon:'nc-bank',       class: '' },
      { path: '/journal',   code:"journal",      title: 'Journal',             icon:'nc-circle-10',    class: '' },
      { path: '/associes',   code:"associes",      title: 'Géstionnaires',       icon:'nc-circle-10',    class: '' },
      { path: '/user', code:"user",   title: 'Utilisateurs',        icon:'nc-single-02', class: '' },
      { path: '/agence',  code:"agence",  title: 'Agences',        icon:'nc-bank', class: '' },
      { path: '/account', code:"account",   title: 'Comptes',        icon:'nc-money-coins', class: '' },
      { path: '/caisse-admin',   code:"caisse_admin",      title: 'Caisse',       icon:'nc-pin-3',    class: '' },
      { path: '/journal-transactions',   code:"journal_transcations",      title:'Transactions',       icon:'nc-chart-bar-32',    class: '' },

     // { path: '/profil',   code:"profil",      title: this.translate.instant('Sidebar.profil'),             icon:'nc-circle-10',    class: '' },
     // { path: '/role',     code:"role",     title: this.translate.instant('Sidebar.role'),             icon:'nc-tag-content',      class: '' },

    //  { path: '/accord',      title: this.translate.instant('Sidebar.accord'),            icon:'nc-briefcase-24',    class: '' },
    //  { path: '/type-transfert',      title: this.translate.instant('Transfert.type'),              icon:'nc-send',  class: '' },
      { path: '/distributions',  code:"distributions",   title: 'Distributions',         icon:'nc-briefcase-24', class: '' },
   //   { path: '/rapport',         title: this.translate.instant('Dashboard.rapport'),       icon:'nc-pin-3',    class: '' },

   //{ path: '/ville',   code:"ville",      title: this.translate.instant('Sidebar.ville'),       icon:'nc-pin-3',    class: '' },
     { path: '',   code:"deconnect",      title: 'Déconnexion',             icon:'nc-icon nc-lock-circle-open',    class: '' },
     //{ path: '',   code:"lng",      title:this.translate.instant('Sidebar.lang'),             icon:'',    class: '' },



  ];
    OPERATEUR: Operateur[] = [
        { path: '/caisse-operateur', code:"caisse-operateur" ,title: 'Caisse',       icon:'nc-refresh-69', class: ''},
        { path: '/charge',   code:"charge",      title: 'Charges',      icon:'nc-chart-bar-32',    class: '' },
        { path: '/depot',  code:"depot",       title:'Depots',            icon:'nc-circle-10',    class: '' },
        { path: '/profil',  code:"profil",       title:'Profil',            icon:'nc-circle-10',    class: '' },
        { path: '',   code:"deconnect",      title:'Déconnexion' ,             icon:'nc-icon nc-lock-circle-open',    class: '' },
       // { path: '',   code:"lng",      title:this.translate.instant('Sidebar.lang'),             icon:'',    class: '' },
    ];

    // Les item en arabe


    MANAGER_ar: Manager[] = [
      { path: '/account-details', code:"caisse",     title:  'الرئيسة',         icon:'nc-bank',       class: '' },
      { path: '/user', code:"user",   title: 'المستخدمون',        icon:'nc-single-02', class: '' },
      { path: '/agence',  code:"agence",  title: 'الوكالات',        icon:'nc-bank', class: '' },
     // { path: '/associes',   code:"associes",      title: this.translate.instant('Sidebar.associes'),       icon:'nc-circle-10',    class: '' },
      { path: '/account', code:"account",   title: 'الحسابات',        icon:'nc-money-coins', class: '' },
      { path: '/depot',   code:"depot",      title: 'الدفعات',             icon:'nc-chart-bar-32',    class: '' },
      { path: '/charge',   code:"charge",      title:'المصاريف',      icon:'nc-chart-bar-32',    class: '' },
      { path: '/capital',   code:"capital",      title:"راس المال",             icon:'nc-money-coins',    class: '' },
      { path: '/journal',   code:"journal",      title: 'اليومية',             icon:'nc-circle-10',    class: '' },
      { path: '/profil',   code:"profil",      title:  "الملف الشخصي",             icon:'nc-circle-10',    class: '' },
      { path: '',   code:"deconnect",      title: 'خروج',             icon:'nc-icon nc-lock-circle-open',    class: '' },

      //{ path: '',   code:"lng",      title:this.translate.instant('Sidebar.lang'),             icon:'',    class: '' },

    ];
     SUPER_ar: Super[] = [
      { path: '/account-details', code:"caisse",     title:  'الرئيسة',         icon:'nc-bank',       class: '' },
      { path: '/user', code:"user",   title: 'المستخدمون',        icon:'nc-single-02', class: '' },
      { path: '/agence',  code:"agence",  title: 'الوكالات',        icon:'nc-bank', class: '' },
     // { path: '/associes',   code:"associes",      title: this.translate.instant('Sidebar.associes'),       icon:'nc-circle-10',    class: '' },
      { path: '/account', code:"account",   title: 'الحسابات',        icon:'nc-money-coins', class: '' },
      { path: '/depot',   code:"depot",      title: 'الدفعات',             icon:'nc-chart-bar-32',    class: '' },
      { path: '/charge',   code:"charge",      title:'المصاريف',      icon:'nc-chart-bar-32',    class: '' },
      { path: '/capital',   code:"capital",      title:"راس المال",             icon:'nc-money-coins',    class: '' },
      { path: '/journal',   code:"journal",      title: 'اليومية',             icon:'nc-circle-10',    class: '' },
      { path: '/profil',   code:"profil",      title:  "الملف الشخصي",             icon:'nc-circle-10',    class: '' },
      { path: '',   code:"deconnect",      title: 'خروج',             icon:'nc-icon nc-lock-circle-open',    class: '' },

        //{ path: '',   code:"lng",      title:this.translate.instant('Sidebar.lang'),             icon:'',    class: '' },

      ];
      ADMIN_ar: Admin[] = [

          { path: '/caisse-admin',  code:"caisse_admin",   title:'الرئيسة',         icon:'nc-bank',       class: '' },
          { path: '/journal',   code:"journal",     title: 'اليومية',             icon:'nc-circle-10',    class: '' },
          { path: '/associes',   code:"associes",      title: 'المسيري',       icon:'nc-circle-10',    class: '' },
          { path: '/user', code:"user",   title: 'المستخدمون',        icon:'nc-single-02', class: '' },
          { path: '/agence',  code:"agence",  title: 'الوكالات',        icon:'nc-bank', class: '' },
          { path: '/account', code:"account",   title: 'الحسابات',        icon:'nc-money-coins', class: '' },
         // { path: '/caisse-admin',   code:"caisse_admin",      title: this.translate.instant('Sidebar.caisse'),       icon:'nc-pin-3',    class: '' },
          { path: '/journal-transactions',   code:"journal_transcations",      title: 'الرسائل',       icon:'nc-chart-bar-32',    class: '' },

          { path: '/profil',   code:"profil",    title:  "الملف الشخصي"   ,             icon:'nc-circle-10',    class: '' },
          { path: '/role',     code:"role",     title:'الأدوار' ,             icon:'nc-tag-content',      class: '' },

        //  { path: '/accord',      title: this.translate.instant('Sidebar.accord'),            icon:'nc-briefcase-24',    class: '' },
        //  { path: '/type-transfert',      title: this.translate.instant('Transfert.type'),              icon:'nc-send',  class: '' },
          { path: '/distributions',  code:"distributions",   title:"توزيع الرسوم" ,         icon:'nc-briefcase-24', class: '' },
       //   { path: '/rapport',         title: this.translate.instant('Dashboard.rapport'),       icon:'nc-pin-3',    class: '' },

          { path: '/ville',   code:"ville",      title: 'المدينة',       icon:'nc-pin-3',    class: '' },
          { path: '',   code:"deconnect",      title:'خروج',             icon:'nc-icon nc-lock-circle-open',    class: '' },
          //{ path: '',   code:"lng",      title:this.translate.instant('Sidebar.lang'),             icon:'',    class: '' },



      ];
      SOUSADMIN_ar: Admin[] = [

        { path: '/profil',   code:"profil",   title:"الملف الشخصي",         icon:'nc-bank',       class: '' },
        { path: '/journal',   code:"journal",      title: 'اليومية',             icon:'nc-circle-10',    class: '' },
        { path: '/associes',   code:"associes",      title: 'المسيرين',       icon:'nc-circle-10',    class: '' },
        { path: '/user', code:"user",   title:'المستخدمون' ,        icon:'nc-single-02', class: '' },
        { path: '/agence',  code:"agence",  title: 'الوكالات',        icon:'nc-bank', class: '' },
        { path: '/account', code:"account",   title: 'الحسابات',        icon:'nc-money-coins', class: '' },
        { path: '/caisse-admin',   code:"caisse_admin",      title: 'الصندوق',       icon:'nc-pin-3',    class: '' },
        { path: '/journal-transactions',   code:"journal_transcations",      title: "Transactions",       icon:'nc-chart-bar-32',    class: '' },

       // { path: '/profil',   code:"profil",      title: this.translate.instant('Sidebar.profil'),             icon:'nc-circle-10',    class: '' },
       // { path: '/role',     code:"role",     title: this.translate.instant('Sidebar.role'),             icon:'nc-tag-content',      class: '' },

      //  { path: '/accord',      title: this.translate.instant('Sidebar.accord'),            icon:'nc-briefcase-24',    class: '' },
      //  { path: '/type-transfert',      title: this.translate.instant('Transfert.type'),              icon:'nc-send',  class: '' },
        { path: '/distributions',  code:"distributions",   title: "توزيع الرسوم",         icon:'nc-briefcase-24', class: '' },
     //   { path: '/rapport',         title: this.translate.instant('Dashboard.rapport'),       icon:'nc-pin-3',    class: '' },

     //{ path: '/ville',   code:"ville",      title: this.translate.instant('Sidebar.ville'),       icon:'nc-pin-3',    class: '' },
       { path: '',   code:"deconnect",      title: 'خروج',             icon:'nc-icon nc-lock-circle-open',    class: '' },
       //{ path: '',   code:"lng",      title:this.translate.instant('Sidebar.lang'),             icon:'',    class: '' },



    ];
      OPERATEUR_ar: Operateur[] = [
          { path: '/caisse-operateur', code:"caisse-operateur" ,title: 'الصندوق',       icon:'nc-refresh-69', class: ''},
          { path: '/depot',  code:"depot",       title:'الدفعات',            icon:'nc-circle-10',    class: '' },
          { path: '/profil',  code:"profil",       title:"الملف الشخصي",            icon:'nc-circle-10',    class: '' },
        { path: '/charge',   code:"charge",      title:'المصاريف',      icon:'nc-chart-bar-32',    class: '' },


          { path: '',   code:"deconnect",      title:'خروج' ,             icon:'nc-icon nc-lock-circle-open',    class: '' },
         // { path: '',   code:"lng",      title:this.translate.instant('Sidebar.lang'),             icon:'',    class: '' },
      ];

      MangerLite_fr: Manager[] = [
        { path: '/manager-caisse-lite', code:"caisse-operateur" ,title: 'Caisse',       icon:'nc-refresh-69', class: ''},
        
        { path: '/profil',  code:"profil",       title:"Profil",            icon:'nc-circle-10',    class: '' },

        { path: '',   code:"deconnect",      title:'Déconnexion' ,             icon:'nc-icon nc-lock-circle-open',    class: '' },
       // { path: '',   code:"lng",      title:this.translate.instant('Sidebar.lang'),             icon:'',    class: '' },
    ];

    MangerLite_ar: Manager[] = [
      { path: '/manager-caisse-lite', code:"manager_lite" ,title: 'الصندوق',       icon:'nc-refresh-69', class: ''},
      
      { path: '/profil',  code:"profil",       title:"الملف الشخصي",            icon:'nc-circle-10',    class: '' },

      { path: '',   code:"deconnect",      title:'خروج' ,             icon:'nc-icon nc-lock-circle-open',    class: '' },
     // { path: '',   code:"lng",      title:this.translate.instant('Sidebar.lang'),             icon:'',    class: '' },
  ];
    public managerItems: any[];
    public adminItems: any[];
    public operateurItems: any[];
    public superItems: any[];
    user:any=[];
    roles:any=[];
    agence:any={}
    isoperateur:boolean = false;
    issupervisor:boolean = false;
    isadmin:boolean = false;
    is_sous_admin:boolean = false;
    ismanager:boolean = false
    manager_info:any;

  issupermanager: boolean = false;

    lang;
  privilleges: any;
  have_to_add_user: boolean;
  have_to_update_user: boolean;
  have_to_enable_desable_user: boolean=false;
  have_to_add_privilege: boolean=false;
  have_manage_user: boolean=false;
  have_manage_distributions: boolean = false;
  have_manage_journal: boolean;
  have_manage_agences: boolean;
  have_manage_privilleges: boolean;
  have_manage_journal_transaction: boolean;
  have_manage_caisse: boolean;
  have_to_update_account: boolean;
  have_add_charge: boolean;
  public sousadminItems: any[];
  manager_number_agencies;

    constructor(private apiService : ApiServiceService, private router: Router,
      public notifyService : NotifyService,
        private translate:TranslateService)
    {  this.translate.use(localStorage.getItem("lng"))
       //localStorage.getItem('lng')=='fr'?this.lang='fr':this.lang='ar';
       //this.lng = localStorage.getItem('lng')
      // this.translate.use(localStorage.getItem("lng"))
      this.lang = localStorage.getItem('lng')
         this.translate.use(localStorage.getItem("lng"))
        this.userLoged();
        this.privilleges = JSON.parse(localStorage.getItem("privilleges"))
        this.initialisationSidebarItem(this.lang)
        this.repartitionPrivilleges()
       // this.getUserSupervisorDetails()
       }

    ngOnInit() {
        //this.apiService.getExpirationSession("TTL");

    }
    userLoged(){
      this.user =JSON.parse(localStorage.getItem("user"));
      this.roles =JSON.parse(localStorage.getItem("user")).roles;

      console.log(this.roles[0])
      console.log(this.roles)
      this.isAdmin();this.isSousAdmin(); this.isManager(); this.isOperateur();this.isSupervisor();this.isSupermanager();
      console.log("IS SUPERVISEUR "+this.issupervisor)
    }
    isManager(){
      let  roles:[]= this.roles.filter(item => item == "MANAGER" );
      console.log(roles)
      if(roles.length >0){
      this.ismanager = true;
      this.manager_info = this.user

      this.manager_number_agencies = localStorage.getItem("manager_number_agencies")
      if(this.manager_number_agencies){this.manager_number_agencies = parseInt(this.manager_number_agencies)
      }else{
        this.manager_number_agencies = 0
      }
    }
    }
    isSupervisor(){
      let  roles:[]= this.roles.filter(item => item ==  "SUPERVISEUR"  );
      console.log(roles)
      if(roles.length >0){
      this.issupervisor = true;
      this.manager_info = JSON.parse(localStorage.getItem("supervisor_manager"))
      this.manager_number_agencies = localStorage.getItem("manager_number_agencies")
      if(this.manager_number_agencies){this.manager_number_agencies = parseInt(this.manager_number_agencies)
      }else{
        this.manager_number_agencies = 0
      }
    }
    }
    isOperateur(){
      let  roles:[]= this.roles.filter(item => item == "OPERATEUR" );
      if(roles.length >0){
      if(localStorage.getItem("agence") !== "undefined"){
          this.agence=JSON.parse(localStorage.getItem("agence"))
        this.isoperateur=true

      }
    }
    }

    isAdmin(){
      let  roles:[]= this.roles.filter(item => item == "ADMIN" );
      if(roles.length >0){
        this.isadmin = true;
    }
    }
    isSousAdmin(){
      let  roles:[]= this.roles.filter(item => item == "SOUSADMIN" );
      if(roles.length >0){
        this.is_sous_admin = true;
      }
    }


    isSupermanager(){
      let  roles:[]= this.roles.filter(item => item == "SUPERMANAGER" );
      if(roles.length >0){
        this.issupermanager = true;
    }
    }

    repartitionPrivilleges(){
      this.privilleges = JSON.parse(localStorage.getItem("privilleges"))
     // console.log("repartitionPrivilleges" +  this.privilleges)
      this.privilleges.forEach(element => {

          if(element == "gestion_users"){
            console.log("We have to manage users")
            this.have_manage_user = true;
            this.have_to_add_user = true;
            this.have_to_update_user = true;
            this.have_to_enable_desable_user = true
          }
          if(element == "gestion_distribution_frais"){
            console.log("We have to manage users")
            this.have_manage_distributions = true;

          }
          if(element == "journal"){
            console.log("We have to manage users")
            this.have_manage_journal = true;

          }

          if(element == "gestion_agences"){
            console.log("We have to manage users")
            this.have_manage_agences = true;

          }
          if(element == "update_account"){

            this.have_to_update_account = true;

          }

          if(element == "gestions_privilleges"){
            console.log("We have to manage users")
            this.have_manage_privilleges = true;

          }

          if(element == "journal_transaction"){
            console.log("We have to manage users")
            this.have_manage_journal_transaction = true;

          }
          if(element == "gestion_caisse"){
            console.log("We have to manage users")
            this.have_manage_caisse = true;

          }
          if(element == "add_user"){
            this.have_to_add_user = true;
          }
          if(element == "update_user" ){
            this.have_to_update_user = true;
          }
          if(element == "enable_desable_user"){
            this.have_to_enable_desable_user = true
          }
          if(element == "add_privilege"){
            this.have_to_add_privilege= true
          }
          if(element == "add_charge"){
            this.have_add_charge= true
          }
      });
      console.log("The list of privilleges");


      //this.privillegeServices.repartitionPrivilleges(this.privilleges)
    }
    public logout(){
      console.log("Logout")
      this.apiService.doLogout().subscribe(
        res=>{
          console.log(res)
        },
        error=>{
          console.log(error)
          localStorage.removeItem('token');
          localStorage.removeItem('refreshToken');
          localStorage.removeItem('user');
          localStorage.removeItem('agence');
          localStorage.removeItem('privilleges')
          localStorage.removeItem("supervisor_manager")

          this.router.navigate(['/dash']);
        },
        ()=>{
          localStorage.removeItem('token');
          localStorage.removeItem('refreshToken');
          localStorage.removeItem('user');
          localStorage.removeItem('agence');
          localStorage.removeItem('privilleges')
          localStorage.removeItem("supervisor_manager")
          this.router.navigate(['/dash']);
          //this.notifyService.successNotify('top','center',this.translate.instant('Message.logout_success'));

        }
      )
  }

    initialisationSidebarItem(langue){
      if(langue === 'fr'){
        console.log("Sidebar infos manager",this.manager_number_agencies)
        this.managerItems = this.MANAGER.filter(managerItem => managerItem);
        this.adminItems = this.ADMIN.filter(adminItem => adminItem);
        this.sousadminItems = this.SOUSADMIN.filter(sousadminItem => sousadminItem);
       
        this.operateurItems = this.OPERATEUR.filter(operateurItem => operateurItem);
        if(this.manager_number_agencies ==1){this.managerItems = this.MangerLite_fr.filter(managerItem => managerItem);}
         this.superItems = this.SUPER.filter(superItem => superItem);
      }else{

      
        this.managerItems = this.MANAGER_ar.filter(managerItem => managerItem);
        this.adminItems = this.ADMIN_ar.filter(adminItem => adminItem);
        this.sousadminItems = this.SOUSADMIN_ar.filter(sousadminItem => sousadminItem);
        this.operateurItems = this.OPERATEUR_ar.filter(operateurItem => operateurItem);
        this.superItems = this.SUPER_ar.filter(superItem => superItem);
        if(this.manager_number_agencies ==1){this.managerItems = this.MangerLite_ar.filter(managerItem => managerItem);}

      }

    }
  }
