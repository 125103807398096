import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.css']
})
export class NotFoundComponent implements OnInit {
  lng:string;
  constructor(private translate:TranslateService) {
    this.translate.use(localStorage.getItem('lng'));
    this.lng =localStorage.getItem('lng');
    console.log(this.lng)
   }

  ngOnInit(): void {
  }

}
