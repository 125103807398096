import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SidebarComponent } from './sidebar.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { createTranslateLoader } from 'app/app.module';
import { HttpClient } from '@angular/common/http';

@NgModule({
    imports: [ RouterModule,
               CommonModule,
               /*TranslateModule.forRoot({
                loader:{
                  provide: TranslateLoader,
                  useFactory: (createTranslateLoader),
                  deps:[HttpClient]
                }
              }),*/
     ],
    declarations: [ SidebarComponent ],
    exports: [ SidebarComponent ]
})

export class SidebarModule {}
