import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class TransfertService {

  public MONTANT_MAX = 300000;
  public MONTANT_NEEDED_ALL_INFOS=30000
  public MIN_AMOUNT_NOT_NEED_FRAIS=300
  public MIN_FRAIS = 0;
  public MIN_FRAIS_NOT_ZERO = 10
  constructor() { }

  public getAgenceNameByManagerPosition(agences:any[],id_agence,manager,manager_childs:any[]){
    let agence=agences.filter(item=>item.id == id_agence)
    if(agence.length>0){


      if(manager.id == agence[0].id_manager || (agence[0].manager_level == manager.level && manager.level == 1) ){
        return agence[0].name
      }

      // 2eme cas manager level > agency manager level
      if(manager.level == 1 && manager.level != agence[0].manager_level){
        if(agence[0].manager_level == 2){
          return agence[0].manager_name
        }else{
          return agence[0].manager_patron_name
        }

      }

      // 3eme cas manager level > 1
      if(manager.level > 1){
        if(agence[0].id_manager == manager.id_parent && agence[0].manager_level == 1 ){
          return agence[0].name
        }
        if(agence[0].id_manager == manager.id_parent && agence[0].manager_level > 1 ){
          return agence[0].manager_name
        }
        if(this.isManagerChilds(manager_childs,agence[0].id_manager)){
          return agence[0].manager_name
        }
        if(manager.level != agence[0].manager_level && agence[0].manager_level == 2){
          return agence[0].manager_name;
        }
        if(manager.level != agence[0].manager_level && agence[0].manager_level >2){
          return agence[0].manager_patron_name;
        }
      }
      return agence[0].name
    }else{
      return ""
    }

  }

  isManagerChilds(childs:any[],id_manager):boolean{
    let manager_child = childs.filter(item => item.id == id_manager)
    if(manager_child && manager_child.length >0){
      return true
    }
    return false;
  }

  searchAgenceById(list,id:String){
    //let str = id.split(' :')
    console.log("Resultat du Split",id)
    let initial_agence_name:String = id;
    let new_str = initial_agence_name.substring(initial_agence_name.length-8)
    let real_code = new_str.replace(/[{()}]/g, '');
    console.log("The new Search filtre of agence Name",new_str)
    console.log("New Code ",real_code)

    //console.log(str)
    let newListe =[];
    newListe = list.filter(item=>item.code_agence == real_code);
    console.log("Inititialisation liste",list)
    console.log("new liste",newListe)
    if(newListe.length>0){
      console.log("new liste",newListe)
      //console.log("str1 "+str[0])
      console.log(newListe)
      return newListe[0].id
    }
    else return null
  }

  searchFromArray(arr, regex) {

    let matches = [];
    for (let title of arr) {
      if (String(title).match(regex)) {
        matches.push(title);
      }
    }
    return matches;
  }

  searchManagerById(list,id:String){
    //let str = id.split(' :')
    console.log("Resultat du Split",id)
    let initial_user_name:String = id;
    let new_str = initial_user_name.substring(initial_user_name.length-10)
    let real_code = new_str.replace(/[{()}]/g, '');
    console.log("The new Search filtre of user Name",new_str)
    console.log("New Code ",real_code)

    //console.log(str)
    let newListe =[];
    newListe = list.filter(item=>item.code_partenaire == real_code);
    console.log("Inititialisation liste",list)
    console.log("new liste",newListe)
    if(newListe.length>0){
      console.log("new liste",newListe)
      //console.log("str1 "+str[0])
      console.log(newListe)
      return newListe[0].id
    }
    else return null
  }

  searchAgenceByCode(list,id:String){
    //let str = id.split(' :')
    console.log("Resultat du Split")
    //console.log(str)
    let newListe =[];
    newListe = list.filter(item=>item.id == id);
    if(newListe.length>0){
      console.log(newListe)
      //console.log("str1 "+str[0])
      console.log(newListe)
      return newListe[0].code_agence
    }
    else return null
  }

   getNowUTC(date) {

    const now = new Date(date);
    return new Date(now.getTime() + (now.getTimezoneOffset() * 60000));
  }

  public  checkAgencyZoneIfOnline(agences,id_agence) {
    let agenceto=[];
    agenceto = agences.filter(item=>(item.id == id_agence))
    if(agenceto.length<1){return false;}
    if(agenceto[0].zone == "connected"){
      return true;
    }else{
      return false;
    }
  }

  searchFromAgencyArray(array , regex){
    let matches = [];
    for (let agence of array) {
      if ((String(agence.name).toLowerCase()).match((regex).toLowerCase()) ||
       (String(agence.phone).toLowerCase()).match(regex.toLowerCase()) ||
       (String(agence.region).toLowerCase()).match(regex.toLowerCase())||
       (String(agence.quartier).toLowerCase()).match(regex.toLowerCase())||
        (String(agence.city).toLowerCase()).match(regex.toLowerCase())) {
        matches.push(agence);
      }
    }
    return matches;
  }

  transferControlFrais(frais){
    if(frais==0 || frais>=10){
      return true
    }
    return false;
  }

  transferControlFraisByAmount(frais,amount){
    if(frais==this.MIN_FRAIS || frais>=this.MIN_FRAIS_NOT_ZERO){
      if(amount<=this.MIN_AMOUNT_NOT_NEED_FRAIS){
        return true
      }else{
        if(frais>=this.MIN_FRAIS_NOT_ZERO){
          return true
        }
      }
      //return true
    }
    return false;
  }

  checkIfHaveToDoActionsByAgenciesStatus(transfert,agencies){

    if((agencies.find(item=>item.id==transfert.id_agence_from && item.active == 1))
    && (agencies.find(item=>item.id==transfert.id_agence_to && item.active == 1)) ){
      return true
    }
    return false;
  }
  checkAgenceIfActive(id_agence,agencies){
    if((agencies.find(item=>item.id==id_agence && item.active == 1)) ){
      return true
    }
    return false;
  }

  chekIfTransfertWithBankOrNot(transfer,id_agence_from,id_agence_to,list_agences:any[]){
    /*if(list_agences.find(item=>(item.id==id_agence_from && item.type=='api'))){
      return true;
    }
    if(list_agences.find(item=>(item.id==id_agence_to && item.type=='api'))){
      return true;
    }
    return false
    */
    if(transfer.bank_operation == 1){
      return true;
    }
    else{
      return false;
    }
    return false
  }

  getTheRealMinimize(list:any[]){
    list= list.filter(item=>item.type!='benefice')
    list.forEach(element => {
      if(!element.type_transfert){
        let charge_depended = list.filter(item=>(
          ((element.status=='canceled'&&item.status=='canceled')||(element.type=='capital_increase_retour' && item.type=='capital_increase_retour'))
        &&(item.code_transaction==element.code_transaction) ))
        if(charge_depended.length>1){
          //list=list.filter(item=>!((element.status=='canceled'&&item.status=='canceled')&&(item.code_transaction==element.code_transaction) &&(this.pipe.transform(item.createdAt ,'yyyy-MM-dd')==this.pipe.transform(element.createdAt ,'yyyy-MM-dd')) ))
          list = list.filter(item=>item.code_transaction!=element.code_transaction)
        }
      }else{
        if(element.transfert_status=='deleted'){
          let transac = list.filter(item=>(item.id_transfer==element.id_transfer)&&element.transfert_status=='deleted')
          if(transac.length>1){
            list = list.filter(item=>item.id_transfer!=element.id_transfer)
          }else{

          }
        }
        if(element.type=='pick_retour'){
          let retraitDepart=list.find(item=>(item.code_transaction==element.code_transaction)&&(item.type=='retrait' && item.pick_reversed==1))
          if(retraitDepart){
            //listToRetire.push(retraitDepart)
            //listToRetire.push(element)
            list = list.filter(item=>item!==retraitDepart && item!==element)
          }

        }
      }
    });
    return list;
  }

  getDateCloture(date){
    let startDate = new Date(date);
    let finalDate =new Date(startDate.setDate(startDate.getDate() - 1));

    return finalDate;
  }

  calculTransfertSommeAmount(list){
    let reduced = list
    .map(function(transfert) {
      return (transfert.amount);
    })
    .reduce(function(a, b) {
      if (b === undefined) return a;
      return a + b;
    }, 0);

    return reduced;
      //return sumWithInitial;
    }
}
